import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UploadImgs from '../simple/UploadImgs';
import ReviewsServices from '../../services/general/reviewsServices';
import { toast } from 'react-toastify';

const ReviewModal = observer(({show, setShow, id}) => {
    const handleClose = () => setShow(false);

    const {app} = useContext(Context);
    
    const [images, setImages] = useState([]);
    const [hoveredRating, setHoveredRating] = useState(0);
    const [selectedRating, setSelectedRating] = useState(0);
    const stars = [1, 2, 3, 4, 5];

    const [ratingError, setRatingError] = useState();

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        if(selectedRating <= 0){
            setRatingError(true);
            return
        }
        const newData = {
            attachments: images,
            product_id: id,
            points: selectedRating,
            text: data.text
        }

        const res = await ReviewsServices.store(newData);
    
        if (res.statusCode === 200) {
            toast.success(app.localizationsItems?.review_moderation);
            setShow(false);
        }else{
            toast.error(res.message)
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal' onSubmit={handleSubmit(CustomSubmit)}>
                <h3 className='text-center'>{app.localizationsItems?.your_review}</h3>
                
                <label className='form-label w-100'>
                    {app.localizationsItems?.rate_product}
                    <ul className="rating rating-new">
                        {stars.map((star, index) => (
                            <li
                                key={index}
                                onMouseEnter={() => setHoveredRating(star)}
                                onMouseLeave={() => setHoveredRating(0)}
                                onClick={() => {
                                    setSelectedRating(star);
                                    setRatingError(false)
                                }}
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill={hoveredRating >= star || selectedRating >= star? '#ffb321' : 'none'}
                                stroke="#ffb321"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                            </svg>
                            </li>
                        ))}
                    </ul>
                    {ratingError && <div className='red'>{app.localizationsItems?.add_rating}</div>}
                </label>
                <label className='form-label w-100'>
                    {app.localizationsItems?.comments}
                    <textarea type="text" className='form-control' required {...register('text')} placeholder={app.localizationsItems?.comments}/>
                </label>
                <label className='form-label w-100'>
                    {app.localizationsItems?.add_images}
                    <div className='reviews-images'>
                        {images?.map((it, index) =>
                            <img src={'https://app.chinchin.kz'+it} key={index} alt="" />
                        )}
                    </div>
                    <div>
                        <UploadImgs 
                            images={images}
                            setImages={setImages}
                        />
                    </div>
                </label>

                <button className='btn btn-animation btn-md fw-bold ms-auto'>{app.localizationsItems?.send}</button>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default ReviewModal